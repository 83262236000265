import { FC } from 'react';

import { useLinkComponent } from '@link';
import { wrap } from '@sitecore/common';
import { LinkCardRendering } from '@sitecore/types/manual/LinkCard';
import { Box, Card, Grid, Heading, IconLoader, Stretch, TextLink } from '@sparky';
import { ChevronRightIcon } from '@sparky/icons';

const LinkCard: FC<LinkCardRendering> = ({ fields, params: { columns = '1', headingLevel = 'h2' } = {} }) => {
  const Link = useLinkComponent();

  if ((!fields.items || fields.items.length === 0) && !fields.title.editable) {
    return null;
  }

  const items = fields.items?.filter(item => item.fields?.link?.value?.href) ?? [];
  const rows = Math.ceil(items.length / parseInt(columns));

  return (
    <Stretch height>
      <Card>
        <Box padding="8">
          <Box paddingBottom="6">
            <Heading size="S" as={headingLevel ?? 'h2'}>
              {wrap(fields.title)}
            </Heading>
          </Box>
          <Grid gridTemplateRows={{ md: `repeat(${rows}, 1fr)` }} flow={{ md: 'column' }} gap="5" as="ul">
            {items?.map((item, i) => (
              <Grid.Item key={i} as="li">
                <Link
                  editable={item.fields.link.editable}
                  linkType={item.fields.link.value.linktype}
                  linkValue={item.fields.link.value}>
                  <TextLink
                    emphasis="medium"
                    leftIcon={
                      <IconLoader iconName={fields.icon?.fields?.icon?.name} fallbackIcon={<ChevronRightIcon />} />
                    }
                    target={item.fields.link.value.target}>
                    {item.fields.link.value.text}
                  </TextLink>
                </Link>
              </Grid.Item>
            ))}
          </Grid>
        </Box>
      </Card>
    </Stretch>
  );
};

export default LinkCard;
